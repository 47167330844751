/** 获取 baseUrl */
export const getBaseUrl = (callback?: any) => {
  const paths = window.location.pathname.split('/');
  if (paths[1] === '#') {
    paths[1] = '';
  }
  let baseUrl = `${window.location.href.split(window.location.pathname)[0]}/`;
  if (paths[1].toLowerCase() === 'iot') {
    baseUrl = `${baseUrl}${paths[1]}/`;
  }
  callback && callback(baseUrl);
  return baseUrl;
}