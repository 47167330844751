import React, { useEffect } from 'react';

import './App.css';
import { getBaseUrl } from './utils';

function App(props: any) {
  useEffect(() => {
    window.location.href = getBaseUrl() + 'dashboard/'
    // registerMicroApps(
    //   [
    //     {
    //       name: 'grebok.spa', //微应用的名称，微应用之间必须确保唯一(微应用中package.json中的name)
    //       entry: '//localhost:5000/dashboard/', //微应用的entry地址
    //       container: '#dashboard',//微应用的容器节点的选择器
    //       activeRule: '/'//微应用的激活规则
    //     },
    //   ]
    // )
    // start({ prefetch: 'all' }) //启动，配置all则主应用start后即开始预加载所有微应用静态资源
    // console.log('app', props)

  }, [])
  return (
    <>
      <div id='adm' />
      <div id='dashboard' />
    </>
  );
}

export default App;
